import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { HttpClient } from "@angular/common/http";
import { FormArray, FormGroup } from '@angular/forms';
import { Storage } from '@ionic/storage-angular';
import { ActionSheetController, AlertController, LoadingController, NavController, Platform, ToastController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Subject } from 'rxjs';
import { Network } from '@capacitor/network';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Router } from '@angular/router';

@Injectable({
	providedIn: 'root'
})

export class HelperService {
	private spinner: any = {
		spinner: 'circles',
		backdropDismiss: false,
	};
	access_token: any;
	token_login: any
	toastErrorPresent:any = true;
	User_id: any;
	private dataObserved = new Subject<any>();
	// currentEvent = this.dataObserved.asObservable();
	constructor(
		private config: ConfigService,
		private http: HttpClient,
		private alertCtrl: AlertController,
		private loadingCntrl: LoadingController,
		private Toast: ToastController,
		private storage: Storage,
		public navCtrl: NavController,
		public platform: Platform,
		private iab: InAppBrowser,
		private router: Router,
		public actionSheetController: ActionSheetController,
	) {

		this.access_token = this.config.getConf('token');
		this.netWork();
		this.storage.create();


	}
	netWork(scrpit?) {
		Network.addListener('networkStatusChange', status => {
			console.log('Network status changed', status);
			if (status.connected) {
				// this.toast('We are back')

			}
			else {

				if (scrpit) {
					this.toast("You are not connected to the internet.  We will save a draft copy of your manuscript locally until you get connected to the internet", "You are working offline");
				}
				if (status.connected == false) {
					// this.toast("We are having trouble connecting to our server, please try again later","No Internet");
					this.networkConnection();
				}
			}
		});

		const logCurrentNetworkStatus = async () => {
			const status = await Network.getStatus();
			console.log('Network status changed', scrpit);

			console.log('Network status:', status);
		};

	}
	async networkConnection() {
		const toastInternet = await this.Toast.create({
			header: "No Internet",
			message: "We are having trouble connecting to our server, please try again later",
			mode: 'md',
			// duration: 5000,
			position: 'bottom',
			cssClass: 'bottomtoast',
			// keyboardClose: true,
			buttons: [
				{
					text: 'Try Again',
					role: 'cancel',

					handler: async () => {
						this.showLoading();
						this.networkConnection();
						const status = await Network.getStatus();
						if (status.connected == true) {
							this.getAppToken();
							const { role } = await toastInternet.onDidDismiss();
							console.log(role);
						}
					}
				}
			]
		});
		const status = await Network.getStatus();
		if (status.connected == false) {
			await toastInternet.present();
		}

	}
	publishEvent(param: any) {
		this.dataObserved.next(param);
	}
	getEvent(): Subject<any> {
		return this.dataObserved;
	}

	setVaidations(form: any, formname?: any, custmMsg?: any): boolean {
		let formErrors = {};
		let formError = false;
		let msg: string;
		let str: string;
		let validationMsg = {};
		if (formname) {
			for (let i in formname) {
				validationMsg[i] = formname[i]
			}
		}

		for (let i in form.controls) {
			if (form.controls[i] instanceof FormGroup) {
				formError = !this.setVaidations(form.controls[i]);
				if (formError) {
					return !formError;
				}
			} else if (form.controls[i] instanceof FormArray) {
				for (let jj = 0; jj < form.controls[i].controls.length; jj++) {
					formError = !this.setVaidations(form.controls[i].controls[jj]);
					if (formError) {
						return !formError;
					}
				}
			}
			if (form.controls[i].errors) {
				for (let j in form.controls[i].errors) {
					if (form.controls[i].errors[j]) {
						formError = true;
						msg = "";
						str = "";
						if (validationMsg[i])
							str = validationMsg[i];
						else {
							if (i.indexOf('_') !== -1) {
								str = i.split("_").map(x => x.charAt(0).toUpperCase() + x.slice(1)).join(" ");
							} else {
								str = i.charAt(0).toUpperCase() + i.slice(1);

								for (let ii = 1; ii < str.length; ii++) {
									if (str.charAt(ii) >= 'A' && str.charAt(ii) <= 'Z') {
										str = str.substring(0, ii) + " " + str.substr(ii);
										ii = ii + 2;
									}
								}
							}
						}
						if (j == "required") {
							msg = "is required";
						} else if (j == "maxlength") {
							let lrn = form.controls[i].errors[j].requiredLength;
							msg = "should be less than " + (lrn + 1) + " characters";
						} else if (j == "minlength") {
							let lrn = form.controls[i].errors[j].requiredLength;
							msg = "should be greater than " + (lrn - 1) + " characters";
						} else if (j == "pattern") {
							let field = str.toLowerCase().replace(/ /g, '_');
							if (custmMsg[field]) {
								msg = custmMsg[field];
								// str = "";
							} else {
								msg = "is Invalid!"
							}
						} else if (j == 'min') {
							if (i == 'price_per_message') {
								// str = "";
								msg = "'Price per Message' should be greater than or equal to $1";
							}
						}

						var m = str + " " + msg;
						this.toast(m, "There was an error");
						break;
					}
				}
				if (formError) {
					break;
				}
			}

		}
		return !formError;
	}
	async toast(msg, h_msg?) {
		const toastTryAgain = await this.Toast.create({
			header: h_msg,
			message: msg,
			mode: 'md',
			// duration: 5000,
			position: 'bottom',
			cssClass: 'bottomtoast',
			// keyboardClose: true,
			buttons: [
				{
					text: 'Try Again',
					role: 'cancel',

					handler: async () => {
						this.showLoading();
						this.getAppToken();
						this.toastErrorPresent = true;
						const { role } = await toastTryAgain.onDidDismiss();

					}
				}
			]
		});

		const toast1 = await this.Toast.create({
			header: h_msg,
			message: msg,
			mode: 'md',
			duration: 5000,
			position: 'bottom',
			// keyboardClose: true,
			cssClass: 'bottomtoast-close',
			buttons: [
				{
					text: 'X',
					role: 'cancel',
					handler: async () => {
						// this.showLoading();
						const { role } = await toast1.onDidDismiss();

					}
				}
			]
		});
		if (h_msg) {
			if(this.toastErrorPresent){
				this.toastErrorPresent = false;
				await toastTryAgain.present();
			}
		}
		if (!h_msg) {
			await toast1.present();
		}
	}

	async hideLoading() {
		await this.loadingCntrl.dismiss();

	}

	async showLoading() {
		const loading = await this.loadingCntrl.create({
			cssClass: 'my-custom-class',
			message: 'Please wait...',
			duration: 2000
		});
		await loading.present();
	}

	postData(apiName, data, flag) {
		this.access_token = this.config.getConf('token')

		let headers;
		headers = {
			headers: {
				Accept: "application/json",
				Authorization: this.access_token
			}
		};
		// }	
		return new Promise((resolve, reject) => {
			var obj = '';
			if (flag == true) {
				obj = data;
				Object.assign(obj, { remember_token: this.token_login });
			} else {
				obj = data;
			}

			this.http.post(this.config.getConf('url') + apiName, data, headers).subscribe(
				(res: any) => {
					resolve(res);
				},
				error => {
					reject(error);
					if (error.status == 0) {
						this.getAppToken();
						//    this.networkConnection();
						// return this.toast("We are having trouble connecting to our server, please try again later","No Internet");
					}
					else if (error.status == 404) {
						this.toast("Invalid api, please try again", "There was an error");
						this.storage.clear();
						// this.navCtrl.navigateRoot(["/sign-in"]);
						return;
					} else if (error.status == 401) {
						this.toast("Invalid api, please try again", "There was an error");
						this.storage.clear();
						// this.navCtrl.navigateRoot(["/sign-in"]);
						return;
					}
					else if (error.status == 500) {
						this.toast("Server is not responding, please try again", "There was an error");
						this.storage.clear();
						// this.navCtrl.navigateRoot(["/sign-in"]);
						return;
					}
					else{
						this.getAppToken();
						// this.toast("There was a temporary problem completing your request. The network is down or unavailable.", "There was an error")
					}
						// this.toast("Something went wrong try again later", "There was an error");
					
					//    console.log("Error, Please try again."+JSON.stringify(error));
					
				}
			);
		});

	}
	getData(apiName) {
		this.access_token = this.config.getConf('token')


		var headers = {
			headers: {
				Accept: "application/json",
				Authorization: this.access_token
			}
		}
		return new Promise((resolve, reject) => {
			var obj = '';

			this.http.get(this.config.getConf('url') + apiName, headers ).subscribe(
				(res: any) => {
					resolve(res);
				},
				error => {
					reject(error);
					console.log("Error, Please try again.");
				}
			);
		});
	}
	b64toBlob(b64Data, contentType) {
		console.log(contentType);

		const byteCharacters = atob(b64Data);
		const byteArrays = [];
		var sliceSize = 512;
		for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			const slice = byteCharacters.slice(offset, offset + sliceSize);

			const byteNumbers = new Array(slice.length);
			for (let i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}

			const byteArray = new Uint8Array(byteNumbers);
			byteArrays.push(byteArray);
		}

		const blob = new Blob(byteArrays, { type: contentType });
		console.log(blob);

		return b64Data;
		// return blob;
	}
	
	async openBrowser() {
		
	await this.storage.get('user').then((data) => {

			this.User_id = data;
			console.log(this.User_id.email , "icoach done");

		  });

		// localStorage.getItem('user') ;

		var icoach = this.User_id.icoach;
		if( icoach == "0")
		{
			var obj = {
				id: this.User_id.id
			}

				
			this.postData("edit_icoach", obj, false).then((res: any) => {
				if (res.api_status == 1) {
					//console.log("icoach done");
				}
			})

		}  
	


		var target = "_self";
		this.postData("expert_link", '', false).then((res: any) => {
			if (res.api_status == 1) {
				let url = res.data;
				const browser = this.iab.create(url+"/?uid="+this.User_id.email, target);
			}
		})

		

	}
	getAppToken() {
		var obj = {
			secret: this.config.getConf('secret_key')
		}
		this.postData('get-token', obj, false).then((res: any) => {
			if (res.api_status == "1") {
				this.hideLoading();
				this.navCtrl.navigateRoot(this.router.url);
				this.config.setConf('token', res.data.access_token);
				this.publishEvent({
					pageurl: this.router.url
				})
			}
		});
		setTimeout(() => {
			//   this.checkUser();

		}, 2000);
	}
}

