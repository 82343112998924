import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { SafePipe } from './safe.pipe';
import { ShortNamePipe } from './short-name.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';


const PIPES = [ SafePipe, ShortNamePipe,SafeHtmlPipe ];

@NgModule({
	imports: [ CommonModule,  IonicModule.forRoot() ],
	declarations: [ PIPES],
	exports: [ PIPES],
	entryComponents: [],
})

export class PipesModule { }


