import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'safe_url'
})
export class SafePipe implements PipeTransform {


  constructor(private sanitizer: DomSanitizer) {
  }

  public transform(url) {
    // if (url) { return ''; }
    if (url) {
      url = url.replace('watch?v=', 'embed/');
    }
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    
  }
  
}
