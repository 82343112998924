import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  static conf = {
    // url:"http://iwrite.testdevurl.com/iwrite/public/api/",
    // url: "https://iwrite.ajvanguard.com/api/",
    url:"https://admin.i-write.io/api/",
    // imgUrl:"http://iwrite.testdevurl.com/iwrite/public/",
    // imgUrl: "https://iwrite.ajvanguard.com/",
    imgUrl:"https://admin.i-write.io/",
    // url:"http://iwrite.testdevurl.com/public/api/",
    // client_id: "77femq91icow2i",
    // client_secret: "ZcOFtQz4TUXyvcsu",
    // redirect_uri: "https://www.linkedin.com/developers/apps/verification/3832193a-1245-406c-8e43-501e79a6c112",
    secret_key: "370bd447ef3a7501ce0f94ebd2cc5b2f",
    client_id: "86w9komxg9522r",
    client_secret: "uCbqCyqEv8OzhDcY",
    redirect_uri: "https://www.linkedin.com/developers/apps/verification/d4dafe3b-a973-4c87-a787-9ac53687ab8d",
    connected: 'We are back!',
    notConnected: 'Could not connect to internet'
  };

  static nav = {};
  constructor() { }
  getConf(key) {
    let val = ConfigService.conf[key];
    if (val != undefined)
      return ConfigService.conf[key];
    else
      return "";
  }

  setConf(k, v) {
    if (k != "")
      ConfigService.conf[k] = v;
  }
  getNav(key) {
    let val = ConfigService.nav[key];
    if (val != undefined) {
      const data = ConfigService.nav[key];
      delete ConfigService.nav[key];
      return data;
    } else {
      return "";
    }
  }
  setNav(k, v) {
    if (k != "")
      ConfigService.nav[k] = v;
  }


}
