import { NgModule } from '@angular/core';
import { BrowserModule,HammerModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
// import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ReactiveFormsModule,FormsModule } from "@angular/forms";
import { HttpClientModule } from '@angular/common/http';
import { IonicStorageModule } from "@ionic/storage-angular";
import { Facebook } from "@ionic-native/facebook/ngx";
import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";
import { LinkedIn } from "@ionic-native/linkedin/ngx";
import { LinkedLogin } from './LinkedLogin';
import { StreamingMedia } from "@ionic-native/streaming-media/ngx";
import { SpeechRecognition } from "@ionic-native/speech-recognition/ngx";
import {CalendarModule } from "ion2-calendar";
import { PipesModule } from "./pipes/pipes.module";
import { PhotoViewer } from "@awesome-cordova-plugins/photo-viewer/ngx";
import { Calendar } from "@awesome-cordova-plugins/calendar/ngx";
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { Badge } from '@awesome-cordova-plugins/badge/ngx';
import { SignInWithApple } from '@awesome-cordova-plugins/sign-in-with-apple/ngx';
@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, 
    ReactiveFormsModule,FormsModule, 
    HttpClientModule, 
    HammerModule,
    IonicStorageModule.forRoot(),
    IonicModule.forRoot(), 
    CalendarModule,PipesModule,
    AppRoutingModule],
  providers: [
    // StatusBar,
    Facebook,
    SpeechRecognition, 
    LinkedIn,
    StreamingMedia,
    LinkedLogin,
    InAppBrowser,
    PhotoViewer, 
    Calendar,
    ScreenOrientation,
    AppVersion,
    SignInWithApple,    
    Badge,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule {}
