import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth'
// import { SplashScreen } from '@ionic-native/splash-screen/ngx';
// import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AlertController, NavController, Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { ConfigService } from './services/config.service';
import { FcmService } from './services/fcm.service';
import { HelperService } from './services/helper.service';
import { SplashScreen } from '@capacitor/splash-screen';
import { Location } from '@angular/common';
import { StatusBar, Style } from '@capacitor/status-bar';
// import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  subscription: any;
  value_login: any;
  constructor(private platform: Platform,
    private router: Router,
    // private statusBar: StatusBar,
    public Config: ConfigService,
    public helper: HelperService,
    public storage: Storage,
    public nav: NavController,
    public fcmservice: FcmService,
    private _location: Location,
    // public screenOrientation: ScreenOrientation,
    public alertController: AlertController

  ) {
    this.initializeApp();
  }

  initializeApp() {
    GoogleAuth.init()

    this.platform.ready().then(async() => {
      // this.statusBar.styleDefault();
      const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
      this.toggleDarkTheme(prefersDark.matches);

      prefersDark.addListener((mediaQuery) => this.toggleDarkTheme(mediaQuery.matches));

      const setStatusBarStyleDark = async () => {
        await StatusBar.setStyle({ style: Style.Dark });
      };
      // console.log('Dark',setStatusBarStyleDark);
      
      const setStatusBarStyleLight = async () => {
        await StatusBar.setStyle({ style: Style.Light });
      };
      // console.log('light',setStatusBarStyleLight);

      const showStatusBar = async () => {
        await StatusBar.show();
      };
      // console.log('show',showStatusBar);
      setTimeout(()=>{
        SplashScreen.hide({
          fadeOutDuration: 1000
        });
      }, 2000)
      if (this.platform.is('hybrid')) {
        this.fcmservice.initPush();
      }
      if (this.platform.is('ios')) {
        // this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.LANDSCAPE);
      }
         this.helper.getAppToken();
      
    });
    this.storage.create();    
    let device_type = this.platform.is('ios') ? 'ios' : 'Android';
    this.Config.setConf("device_type", device_type);

    // this.value_login = localStorage.getItem('value_login');
    // console.log(this.value_login + 'value_login');

    setTimeout(() => {
      this.checkUser();

      this.storage.get("value_login").then(value_login => {
        if(value_login){
          if (value_login != undefined && value_login != "" && value_login != null && value_login.ischeckedremember == 1 || value_login.ischeckedremember == true) {
            if(this.fcmservice.flagNotifcation){
              if (this.fcmservice.flagNotifcationType == "community") {
                
                this.nav.navigateRoot(['/community']);
              }
              else{
                this.nav.navigateRoot(['/courselist']);
              }
            }else{
              this.nav.navigateRoot(['intro'])
            }
            console.log('value_login');
          }
        }
        else{
          console.log('checkUser');
          
          // this.nav.navigateRoot(['/tutorials']);
          this.nav.navigateRoot(['/welcome']);

          // this.nav.navigateRoot(['/sign-in']);

        }

      });
      


      // this.getAppToken();

    }, 1000); 

    this.platform.backButton.subscribeWithPriority(10, (processNextHandler) => {
      if (this._location.isCurrentPathEqualTo('/dashboard')) {
        // if (this._location.isCurrentPathEqualTo('/tabs/tab1')) {
        this.showExitConfirm();
        processNextHandler();
      }
      console.log('Back press handler!');

    });

    this.platform.backButton.subscribeWithPriority(5, () => {
      console.log('Handler called to force close!');
      this.alertController.getTop().then(r => {
        if (r) {
          navigator['app'].exitApp();
        }
      }).catch(e => {
        console.log(e);
      })
    });
  }


  toggleDarkTheme(shouldAdd) {
    document.body.classList.toggle('dark', shouldAdd);
    console.log(shouldAdd + 'shouldAdd');
    
  }

  ionViewWillEnter() {
    this.subscription = this.platform.backButton.subscribe(() => {
      navigator['app'].exitApp();
    });
    console.log("subscription", this.subscription);

  }

  showExitConfirm() {
    this.alertController.create({
      header: 'App termination',
      message: 'Do you want to close the app?',
      backdropDismiss: false,
      buttons: [{
        text: 'Stay',
        role: 'cancel',
        handler: () => {
          console.log('Application exit prevented!');
        }
      }, {
        text: 'Exit',
        handler: () => {
          navigator['app'].exitApp();
        }
      }]
    })
      .then(alert => {
        alert.present();
      });
  }


  checkUser() {
    this.storage.get("user").then(user => {
      if (user != undefined && user != "" && user != null && user.is_verify == 1) {
        this.helper.getData("getuser?id=" + user.id).then((res: any) => {
          this.Config.setConf("userdetails", res);
          // this.nav.navigateRoot(['/tabs/tab1']);
          if(this.fcmservice.flagNotifcation){
            if (this.fcmservice.flagNotifcationType == "community") {
                
              this.nav.navigateRoot(['/community']);
            }
            else{
              this.nav.navigateRoot(['/courselist']);
            }
          }else{
            this.nav.navigateRoot(['intro'])
          }
        });
        // this.nav.navigateRoot(['/tabs/tab1']);
      }
      else{
        this.nav.navigateRoot(['/tutorials']);
        console.log('enter check user else');
        
      }
    });
  }


}
