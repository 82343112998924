import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  // { path: '', redirectTo: '/tutorials', pathMatch: 'full' },
  { path: '', redirectTo: '/welcome', pathMatch: 'full' },

  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'sign-in',
    loadChildren: () => import('./pages/sign-in/sign-in.module').then( m => m.SignInPageModule)
  },
  {
    path: 'sign-up',
    loadChildren: () => import('./pages/sign-up/sign-up.module').then( m => m.SignUpPageModule)
  },
  {
    path: 'forgot',
    loadChildren: () => import('./pages/forgot/forgot.module').then( m => m.ForgotPageModule)
  },
  {
    path: 'i-calculator',
    loadChildren: () => import('./pages/i-calculator/i-calculator.module').then( m => m.ICalculatorPageModule)
  },
  {
    path: 'courselist',
    loadChildren: () => import('./pages/courselist/courselist.module').then( m => m.CourselistPageModule)
  },
  {
    // path: 'timelinemobile',
    path:'timeline',
    loadChildren: () => import('./pages/timelinemobile/timelinemobile.module').then( m => m.TimelinemobilePageModule)
  },
  {
    path: 'profile-edit',
    loadChildren: () => import('./pages/profile-edit/profile-edit.module').then( m => m.ProfileEditPageModule)
  },
  {
    path: 'taskcompelete',
    loadChildren: () => import('./pages/taskcompelete/taskcompelete.module').then( m => m.TaskcompeletePageModule)
  },
  {
    path: 'user-check',
    loadChildren: () => import('./pages/user-check/user-check.module').then( m => m.UserCheckPageModule)
  },
  {
    path: 'icalculator-details',
    loadChildren: () => import('./pages/icalculator-details/icalculator-details.module').then( m => m.IcalculatorDetailsPageModule)
  },
  {
    path: 'comments',
    loadChildren: () => import('./pages/comments/comments.module').then( m => m.CommentsPageModule)
  },
  {
    path: 'courselist-module',
    loadChildren: () => import('./pages/courselist-module/courselist-module.module').then( m => m.CourselistModulePageModule)
  },
  {
    path: 'intro',
    loadChildren: () => import('./pages/intro/intro.module').then( m => m.IntroPageModule)
  },
  {
    path: 'tutorials',
    loadChildren: () => import('./pages/tutorials/tutorials.module').then( m => m.TutorialsPageModule)
  },
  {
    path: 'terms-condtion',
    loadChildren: () => import('./pages/terms-condtion/terms-condtion.module').then( m => m.TermsCondtionPageModule)
  },
  {
    path: 'welcome',
    loadChildren: () => import('./pages/welcome/welcome.module').then( m => m.WelcomePageModule)
  }


];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
