import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { LocalNotifications } from "@capacitor/local-notifications";
import { NavController } from '@ionic/angular';
import { Badge } from '@awesome-cordova-plugins/badge/ngx';

@Injectable({
  providedIn: 'root'
})
export class FcmService {

  flagNotifcation:any = false;
  flagNotifcationType :any;
  constructor(private router: Router,
    public nav: NavController,
    public badge:Badge
    ) { }


  initPush() {
    this.registerPush();

  }
  private registerPush() {
    PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // No permission for push granted
      }
    });

    PushNotifications.addListener('registration',
      (token: Token) => {
        localStorage.setItem('token', token.value);
        console.log('Push registration success, token: ' + token.value);
      }
    );;

    PushNotifications.addListener('registrationError',
      (error: any) => {
        console.log('Error on registration: ' + JSON.stringify(error));
      }
    )

    PushNotifications.addListener('pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        console.log('Push received: ' + JSON.stringify(notification));
        this.getCount();        
        // this.badge.increase(1);
        this.localNotificationSchedule(notification)
      }
    );

    PushNotifications.addListener('pushNotificationActionPerformed',
      async (notification: ActionPerformed) => {
        const notificationList = await PushNotifications.removeAllDeliveredNotifications();
        console.log('remove notifications', notificationList);
        console.log('Push action performed: ' + JSON.stringify(notification));

        if (notification.notification.data.type == "community") {
          // this.nav.navigateRoot(['/tabs/tab2']);
          this.flagNotifcation = true;
          this.flagNotifcationType = "community";
          this.nav.navigateRoot(['/community']);
        }
        else{
          this.flagNotifcation = true;
          this.flagNotifcationType = "courselist";
          this.nav.navigateRoot(['/courselist']);
        }
      }
    );
    LocalNotifications.addListener('localNotificationActionPerformed',
      async (notification) => {
        const notificationList = await PushNotifications.removeAllDeliveredNotifications();
        console.log('remove notifications', notificationList);
        console.log('localNotificationActionPerformed: ' + JSON.stringify(notification));
        console.log(notification.notification,'notification.notification');
        
        if (notification.notification.actionTypeId == "community") {
          // this.nav.navigateRoot(['/tabs/tab2']);
          this.flagNotifcation = true;
          this.flagNotifcationType = "community";
          this.nav.navigateRoot(['/community'])

        }
        else{
          this.flagNotifcation = true;
          this.flagNotifcationType = "courselist";
          this.nav.navigateRoot(['/courselist']);
        }
      }
    );
  }
  localNotificationSchedule(notification) {
    console.log('schedule local notification');

    LocalNotifications.schedule({
      notifications: [
        {
          title: notification.title,
          body: notification.body,
          id: 1,
          actionTypeId:notification.data.type,
        }
      ]
    });
  }
  async getCount(){
    console.log('getCount');
    
    const notificationList = await PushNotifications.getDeliveredNotifications();
    console.log('delivered notifications', notificationList);
    
  }
}
