import { Injectable } from "@angular/core";
import { ConfigService } from "../app/services/config.service";
import { HelperService } from "./services/helper.service";
import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";
import { NavController, Platform } from "@ionic/angular";
import { Storage } from "@ionic/storage";

@Injectable()
export class LinkedLogin {
  CALLBACK_URL: any =
    "https://www.linkedin.com/developers/apps/verification/d4dafe3b-a973-4c87-a787-9ac53687ab8d";
  constructor(
    private iab: InAppBrowser,
    public helper: HelperService,
    public Config: ConfigService,
    public platform: Platform,
    public Storage: Storage,
    public nav: NavController
  ) {
    this.Storage.create();
  }
  getLinkedInToken(cb) {
    console.log('cb', cb);

    var target = "_blank";

    var options = "location=no,hidden=yes,beforeload=yes";
    let url = "https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=86w9komxg9522r&scope=r_liteprofile%20r_emailaddress&state=linkedin0487417621&redirect_uri=https://www.linkedin.com/developers/apps/verification/d4dafe3b-a973-4c87-a787-9ac53687ab8d";

    const browser = this.iab.create(url, target);
    browser.on("loadstart").subscribe((event) => {
      console.log("event", event);
      if (event.url.indexOf("?code=") > -1 && event.url.indexOf("&state=") > -1 && event.url.split("?code=")[0] == this.CALLBACK_URL) {
        var str = event.url;
        var authCode = str.substring(
          str.lastIndexOf("?code=") + 6,
          str.lastIndexOf("&state")
        );

        if (authCode) {
          console.log('authCode if');

          this.getAccessToken(authCode, cb);
        }
        browser.close();
      }
    });
  }


  getAccessToken(authCode, cb) {
    console.log(authCode);
    console.log('func cll');

    let devicetype = this.Config.getConf("device_type") ? this.Config.getConf("device_type") : "NOTFOUND";
    if (!this.platform.is('hybrid')) {
      var device_id = "evPaNCoETbOtOrCSgDHRwv:APA91bFOL08AWT7CWKZFLMRC8sJZt-z-Va5dkb36vJZUWuqA8Pv8Ym9zOGR_tB_KQSRPa6aAu8rlI2iJ_iH6qeg655599HoGVKyfYdY10pOre4DFeDxxMegqW7q3oLDFaQPpb53rlSf6"
    }
    else {
      var device_id = localStorage.getItem('token');
    }
    var obj = {
      code: authCode,
      device_id: device_id,
      device_type: devicetype,
      client_id: this.Config.getConf('client_id'),
      client_secret: this.Config.getConf('client_secret'),
      redirect_uri: this.Config.getConf('redirect_uri'),
    }
    this.helper.postData("get_user_token", obj, false).then((res: any) => {
      if (res.api_status == 1) {
        console.log('res', res);
        // var email_Address = res.data.email.elements[0].handle.emailAddress;
        // var email_Address1 = res.data.email.elements[0].handle.emailAddress;
        // console.log(email_Address1, 'email_Address1');


        // console.log(email_Address,'email_Address');

        var obj1 = {
          device_id: device_id,
          device_type: devicetype,
          social_id: res.data.id,
          name: res.data.name,
          // email:email_Address,
          social_type: "linkedIn",
        }
        this.helper.showLoading();
        this.helper.postData("usersociallogin", obj1, false).then((response: any) => {
          if (response.api_status == 1) {
            this.helper.hideLoading();
            console.log(response);
            this.Storage.set('user', response.data);
            this.helper.toast(response.api_message);
            this.Storage.set('value_login',response.data);;
            this.nav.navigateRoot('inro');
          }
          else if (response.api_status == 0) {
            this.Config.setNav('social_params', obj1)
            this.nav.navigateRoot('user-check');
          }
          else {
            console.log('try again');

          }
        });
      }
    });

  }
}
